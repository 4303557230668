//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import CivilStatusType    from '@constants/CivilStatusType';
import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialWidgetConfiguration = {
    interest:            null,
    redemption:          null,
    income:              null,
    equity:              null,
    civilStatus:         CivilStatusType.single,
    churchTaxPercentage: 0,
    cashflowPerMonth:    0,
};

const initialState = Object.freeze({
    units:            [],
    userId:           null,
    externalUserId:   null,
    colorSettings:    {},
    contactForm:      {},
    calculation:      initialWidgetConfiguration,
    showThankYouPage: false,
});

const widgetSlice = createSlice({
    name:     'widget',
    initialState,
    reducers: {
        fetchUnits:                      LoadingLevelHelper.increaseLoadingEmptyReducer(),
        fetchUnitsSucceeded:             LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                units: {
                    $set: _.get(action.payload, 'units', []),
                },
            });
        }),
        fetchUnitsFailed:                LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        setCalculationFields:            (state, action) => {
            const { payload } = action;
            let stateToUse    = state;
            const newUnit     = _.get(payload, 'unit');
            const currentUnit = _.get(state, 'calculation.unit');

            if (
                newUnit &&
                currentUnit !== newUnit
            ) {
                stateToUse = update(state, {
                    calculation: {
                        equity: {
                            $set: initialState.calculation.equity,
                        },
                    },
                });
            }

            return update(stateToUse, {
                calculation: {
                    $merge: payload,
                },
            });
        },
        setUserId:                       (state, action) => {
            return update(state, {
                userId: {
                    $set: _.get(action, 'payload.userId', null),
                },
            });
        },
        setExternalUserId:               (state, action) => {
            return update(state, {
                externalUserId: {
                    $set: _.get(action, 'payload.externalUserId', null),
                },
            });
        },
        fetchCalculationResult:          () => {
        },
        fetchCalculationResultFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchCalculationResultSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                calculation: {
                    $merge: _.omit(_.get(action, 'payload.data'), 'unit'),
                },
            });
        }),
        fetchColorSettings:              LoadingLevelHelper.increaseLoadingEmptyReducer(),
        fetchColorSettingsFailed:        LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchColorSettingsSucceeded:     LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                colorSettings: {
                    $merge: _.get(action, 'payload.colorSettings'),
                },
            });
        }),
        setColorSettings:                (state, action) => {
            return update(state, {
                colorSettings: {
                    $set: _.get(action, 'payload.colorSettings'),
                },
            });
        },
        sendContactForm:                 LoadingLevelHelper.increaseLoadingEmptyReducer(),
        sendContactFormFailed:           LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        sendContactFormSucceeded:        LoadingLevelHelper.decreaseLoading((state) => {
            return update(state, {
                contactForm:      {
                    $set: initialState.contactForm,
                },
                showThankYouPage: {
                    $set: true,
                },
            });
        }),
        setContactForm:                  (state, action) => {
            return update(state, {
                contactForm: {
                    $merge: _.get(action, 'payload'),
                },
            });
        },
        openContactForm:                 (state) => {
            return update(state, {
                contactForm:      {
                    $set: initialState.contactForm,
                },
                showThankYouPage: {
                    $set: false,
                },
            });
        },
    },
});

export const WidgetActions = widgetSlice.actions;

export const WidgetReducer = widgetSlice.reducer;

export const useWidget = (dispatch) => bindActionCreators(WidgetActions, dispatch);

export { initialWidgetConfiguration };

export default widgetSlice;
