//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes            from '@components/PropTypes';
import UnitFields           from '@constants/UnitFields';
import InputRenderHelper    from '@helper/InputRenderHelper';
import useContextTranslator from '@hooks/ContextTranslator';
import { useUnit }          from '@slices/unit';
import LabeledInputType     from '@stateless/atomic/LabeledInput/LabeledInputType';
import FacilityList         from '@stateless/composed/FacilityList';
import selectEditUnit       from '@store/selectors/unit';

const propTypes = {
    onUpdateData: PropTypes.func,
    validations:  PropTypes.object,
};

const OutdoorFacilityList = ({
    validations,
    onUpdateData,
}) => {
    const dispatch              = useDispatch();
    const unitActions           = useUnit(dispatch);
    const unit                  = useSelector(selectEditUnit);
    const translator            = useContextTranslator('components.outdoorFacilityList');
    const outdoorFacilities     = _.get(unit, UnitFields.outdoorFacilities, []);
    const inputRenderer         = new InputRenderHelper({
        validations,
        data: unit,
        onUpdateData,
    });
    const gridColumnStyle       = {
        gridTemplateColumns: '6fr 4fr 3fr 3fr 2fr',
    };
    const outdoorFacilityFields = [
        {
            headerText:  translator.t('description'),
            renderInput: (index) => inputRenderer.renderTextInput({
                type:  LabeledInputType.lightWithoutLabel,
                field: [UnitFields.outdoorFacilities, index, UnitFields.outdoorFacilityDescription],
            }),
        },
        {
            headerText:  translator.t('buyingPrice'),
            renderInput: (index) => inputRenderer.renderNumberInput({
                type:  LabeledInputType.lightWithoutLabel,
                field: [UnitFields.outdoorFacilities, index, UnitFields.outdoorFacilityPurchasePrice],
            }),
        },
        {
            headerText:  translator.t('depreciationPercent'),
            renderInput: (index) => inputRenderer.renderNumberInput({
                type:  LabeledInputType.lightWithoutLabel,
                field: [UnitFields.outdoorFacilities, index, UnitFields.outdoorFacilityDepreciationPercent],
            }),
        },
        {
            headerText:  translator.t('depreciationDuration'),
            renderInput: (index) => inputRenderer.renderNumberInput({
                type:  LabeledInputType.lightWithoutLabel,
                field: [UnitFields.outdoorFacilities, index, UnitFields.outdoorFacilityDepreciationDuration],
            }),
        },
    ];

    function addOutdoorFacilityClicked() {
        unitActions.addOutdoorFacility();
    }

    function removeOutdoorFacilityClicked(index) {
        unitActions.deleteOutdoorFacility({
            index,
        });
    }

    return (
        <FacilityList
            emptyText={translator.t('noOutdoorFacilities')}
            addText={translator.t('addOutdoorFacility')}
            addFacilityClicked={addOutdoorFacilityClicked}
            deleteFacilityClicked={removeOutdoorFacilityClicked}
            facilityList={outdoorFacilities}
            facilityFields={outdoorFacilityFields}
            gridColumnStyle={gridColumnStyle}
        />
    );
};

OutdoorFacilityList.propTypes = propTypes;

export default OutdoorFacilityList;
