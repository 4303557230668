//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

class Merge {
    static fuseArraysIgnoringUndefined(objectValue, sourceValue) {
        const newArray = [];

        for (let index = 0; index < Math.max(objectValue.length, sourceValue.length); index++) {
            if (sourceValue[index] === undefined) {
                newArray[index] = objectValue[index];

                continue;
            }

            newArray[index] = Merge.mergeWith(
                objectValue[index],
                sourceValue[index],
            );
        }

        return newArray;
    }

    static keepUndefinedInMerge(objectValue, sourceValue) {
        if (sourceValue === undefined) {
            return '';
        }

        if (
            _.isArray(objectValue) &&
            _.isArray(sourceValue)
        ) {
            return Merge.fuseArraysIgnoringUndefined(objectValue, sourceValue);
        }

        return undefined;
    }

    static mergeWith(objectOne, objectTwo) {
        return _.mergeWith(
            _.cloneDeep(objectOne),
            _.cloneDeep(objectTwo),
            Merge.keepUndefinedInMerge,
        );
    }
}

export default Merge;
