//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import BreadCrumbBar                        from '@connected/BreadCrumbBar';
import CalculationFields                    from '@constants/CalculationFields';
import CustomerFields                       from '@constants/CustomerFields';
import Formatter                            from '@helper/Formatter';
import InputRenderHelper                    from '@helper/InputRenderHelper';
import useContextTranslator                 from '@hooks/ContextTranslator';
import { useCalculation }                   from '@slices/calculation';
import DropDown                             from '@stateless/atomic/DropDown';
import Headline                             from '@stateless/atomic/Headline';
import HeadlineType                         from '@stateless/atomic/Headline/HeadlineType';
import Spacer                               from '@stateless/atomic/Spacer';
import CalculationNavigationBar             from '@stateless/composed/CalculationNavigationBar';
import LabeledWrapper                       from '@stateless/composed/LableledWrapper';
import LayoutScreen                         from '@stateless/composed/LayoutScreen';
import PageTitle                            from '@stateless/composed/PageTitle';
import { selectCurrentCalculationCustomer } from '@store/selectors/calculation';
import { selectCustomers }                  from '@store/selectors/customer';

import styles from './styles.module.scss';

const CalculationCustomerScreen = () => {
    const translator          = useContextTranslator('screens.calculationScreen');
    const customer            = useSelector(selectCurrentCalculationCustomer);
    const customers           = useSelector(selectCustomers);
    const mappedCustomers     = customers.map((customerEntry) => {
        const contactPerson                  = _.get(customerEntry, CustomerFields.contactPersonOne);
        const { firstname, lastname, email } = contactPerson;
        const label                          = (
            (
                firstname &&
                lastname
            ) ?
                `${firstname} ${lastname}` :
                email
        );

        return {
            value: customerEntry,
            label,
        };
    });
    const inputRenderer       = new InputRenderHelper({
        validations:  [],
        data:         customer,
        onUpdateData: _.noop,
    });
    const dispatch            = useDispatch();
    const calculationActions  = useCalculation(dispatch);
    const defaultFieldOptions = {
        disabled: true,
        type:     null,
    };

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            _.set(changeSet, path, value);
            calculationActions.updateCalculationData({
                calculation: changeSet,
            });
        };
    }

    function onCustomerChanged(newCustomer) {
        onUpdateData(CalculationFields.customer)(newCustomer);
    }

    function renderContactPerson(contactPersonField) {
        return (
            <LabeledWrapper
                label={translator.t(contactPersonField)}
                columns={2}
            >
                {inputRenderer.renderTextInput({
                    label: translator.t('name'),
                    field: [contactPersonField, CustomerFields.contactPersonLastname],
                    ...defaultFieldOptions,
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('firstname'),
                    field: [contactPersonField, CustomerFields.contactPersonFirstname],
                    ...defaultFieldOptions,

                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('street'),
                    field: [contactPersonField, ...CustomerFields.contactPersonStreet],
                    ...defaultFieldOptions,

                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('houseNumber'),
                    field: [contactPersonField, ...CustomerFields.contactPersonHouseNumber],
                    ...defaultFieldOptions,

                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('postalCode'),
                    field: [contactPersonField, ...CustomerFields.contactPersonPostalCode],
                    ...defaultFieldOptions,

                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('city'),
                    field: [contactPersonField, ...CustomerFields.contactPersonCity],
                    ...defaultFieldOptions,

                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('email'),
                    field: [contactPersonField, CustomerFields.contactPersonEmail],
                    ...defaultFieldOptions,

                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('mobile'),
                    field: [contactPersonField, CustomerFields.contactPersonMobile],
                    ...defaultFieldOptions,
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('phone'),
                    field: [contactPersonField, CustomerFields.contactPersonPhonePrivate],
                    ...defaultFieldOptions,
                })}
                {inputRenderer.renderTextInput({
                    label: translator.t('phoneBusiness'),
                    field: [contactPersonField, CustomerFields.contactPersonPhoneWork],
                    ...defaultFieldOptions,

                })}
            </LabeledWrapper>
        );
    }

    return (
        <>
            <PageTitle
                title={I18n.t('calculation')}
            />
            <LayoutScreen>
                <BreadCrumbBar />
                <div className={styles.calculationCustomerScreen}>
                    <div className={styles.headerNavigationRow}>
                        <Headline
                            type={HeadlineType.headline2}
                            title={translator.t('calculationCustomerTitle')}
                        />
                        <CalculationNavigationBar />
                    </div>
                    <Spacer height={10} />
                    <div className={styles.halfWidth}>
                        <DropDown
                            options={mappedCustomers}
                            placeholder={translator.t('selectPerson')}
                            noOptionsMessage={translator.t('noOptionsMessage')}
                            onChange={onCustomerChanged}
                            value={customer}
                            pickFirstOption={false}
                        />
                    </div>
                    <div className={styles.divider}>
                        <div>
                            {renderContactPerson(CustomerFields.contactPersonOne)}
                            {renderContactPerson(CustomerFields.contactPersonTwo)}
                        </div>
                        <div>
                            <LabeledWrapper label={translator.t('taxesAndFinance')}>
                                {inputRenderer.renderTextInput({
                                    label:     translator.t('maritalStatus'),
                                    field:     CustomerFields.civilStatus,
                                    formatter: Formatter.formatTranslated('familyStatusOptions'),
                                    ...defaultFieldOptions,
                                })}
                                {inputRenderer.renderTextInput({
                                    label:     translator.t('churchTax'),
                                    field:     CustomerFields.churchTaxPercentage,
                                    formatter: Formatter.formatToPercent,
                                    ...defaultFieldOptions,
                                })}
                                {inputRenderer.renderCurrencyInput({
                                    label: translator.t('yearlyTaxableIncome'),
                                    field: CustomerFields.annualTaxableIncome,
                                    value: _.get(customer, CustomerFields.annualTaxableIncome),
                                    ...defaultFieldOptions,
                                })}
                                <div />
                                {inputRenderer.renderPercentInput({
                                    label: translator.t('incomeRaise'),
                                    field: CustomerFields.increaseInIncome,
                                    ...defaultFieldOptions,
                                })}
                                {inputRenderer.renderTextInput({
                                    label:     translator.t('incomeRaiseInterval'),
                                    field:     CustomerFields.increaseInIncomeInterval,
                                    formatter: Formatter.formatToInterval,
                                    ...defaultFieldOptions,
                                })}
                            </LabeledWrapper>
                        </div>
                    </div>
                    <CalculationNavigationBar />
                </div>
            </LayoutScreen>
        </>
    );
};

export default CalculationCustomerScreen;
