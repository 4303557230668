//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    additionalIncomePerMonth:         'additionalIncomePerMonth',
    additionalIncomeDuration:         'additionalIncomeDuration',
    interest:                         'interest',
    redemption:                       'redemption',
    income:                           'income',
    equity:                           'equity',
    civilStatus:                      'civilStatus',
    churchTaxPercentage:              'churchTaxPercentage',
    unit:                             'unit',
    equityCapital:                    'equityCapital',
    customer:                         'customer',
    calculationFields:                'calculationFields',
    increaseInIncome:                 'increaseInIncome',
    increaseInIncomeInterval:         'increaseInIncomeInterval',
    rentIncrease:                     'rentIncrease',
    rentIncreaseInterval:             'rentIncreaseInterval',
    increaseInOperatingCosts:         'increaseInOperatingCosts',
    increaseInOperatingCostsInterval: 'increaseInOperatingCostsInterval',
    increaseInValue:                  'increaseInValue',
    increaseInValueInterval:          'increaseInValueInterval',
    calculationResult:                'calculationResult',
    cashflowPerMonth:                 'cashflowPerMonth',
    additionalPurchasingCostsSource:  'additionalPurchasingCostsSource',
    mediationCostsInEuro:             'mediationCostsInEuro',
    notaryFeesInEuroCentEuro:         'notaryFeesInEuroCentEuro',
    realEstateTransferInEuro:         'realEstateTransferInEuro',
    total:                            'total',
    financingAmount:                  'financingAmount',
    propertyManagerCostsPerMonth:     'propertyManagerCostsPerMonth',
    unitManagerCostsPerMonth:         'unitManagerCostsPerMonth',
    reservesPerMonth:                 'reservesPerMonth',
    taxEffectPerMonth:                'taxEffectPerMonth',
    incomeSum:                        'incomeSum',
    expensesSum:                      'expensesSum',
    rentIncomePerMonth:               'rentIncomePerMonth',
    redemptionPerMonth:               'redemptionPerMonth',
    interestPerMonth:                 'interestPerMonth',
    purchasePrice:                    'purchasePrice',
    rentalYield:                      'rentalYield',
    returnOnEquity:                   'returnOnEquity',
    step:                             'step',
});
