//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import CalculationFields          from '@constants/CalculationFields';
import CivilStatusType            from '@constants/CivilStatusType';
import { getCivilStatusOptions }  from '@constants/CivilStatusType';
import ConfigurationValues        from '@constants/ConfigurationValues';
import Formatter                  from '@helper/Formatter';
import { WidgetActions }          from '@slices/widget';
import RangeSlider                from '@stateless/atomic/RangeSlider';
import RangeSliderType            from '@stateless/atomic/RangeSlider/RangeSliderType';
import WidgetResult               from '@stateless/atomic/WidgetResult';
import WidgetUnit                 from '@stateless/atomic/WidgetUnit';
import RadioCheckBox              from '@stateless/composed/RadioCheckBox';
import WidgetContactForm          from '@stateless/composed/WidgetContactForm';
import selectCalculation          from '@store/selectors/widget';
import { selectUnits }            from '@store/selectors/widget';
import { selectCashflowPerMonth } from '@store/selectors/widget';
import { selectColorSettings }    from '@store/selectors/widget';
import Colors                     from '@styles/colors.module.scss';

import styles from './styles.module.scss';

const CalculationWidget = () => {
    const dispatch                              = useDispatch();
    const location                              = useLocation();
    const [showContactForm, setShowContactForm] = useState(false);
    const colorSettings                         = useSelector(selectColorSettings);
    const calculation                           = useSelector(selectCalculation);
    const units                                 = useSelector(selectUnits);
    const cashflowPerMonth                      = useSelector(selectCashflowPerMonth);
    const primaryColor                          = _.get(colorSettings, 'mainColor', Colors.darkGreen);
    const accentColor                           = _.get(colorSettings, 'accentColor', Colors.lightGreen);
    const fontColor                             = _.get(colorSettings, 'textColor', Colors.white);
    const backgroundColor                       = _.get(colorSettings, 'backgroundColor', Colors.grayTuatara);
    const backgroundColorStyle                  = {
        backgroundColor,
    };
    const searchParameters                      = new URLSearchParams(location.search);
    const userId                                = searchParameters.get('userId');
    const externalUserId                        = searchParameters.get('feuser');
    const selectedUnit                          = _.get(calculation, CalculationFields.unit);
    const currentPurchasePrice                  = _.get(selectedUnit, 'purchasePrice', 0);

    function updateCalculationField(field, value) {
        return (eventValue) => {
            dispatch(WidgetActions.setCalculationFields({
                [field]: value || eventValue,
            }));
        };
    }

    useEffect(
        () => {
            const setInitialUnit = () => {
                updateCalculationField(CalculationFields.unit, _.first(units))();
            };

            if (
                (
                    !selectedUnit &&
                    !_.isEmpty(units)
                ) ||
                !_.includes(units, selectedUnit)
            ) {
                setInitialUnit();
            }
        },
        [
            selectedUnit,
            units,
        ],
    );

    useEffect(
        () => {
            dispatch(WidgetActions.setUserId({
                userId,
            }));
            dispatch(WidgetActions.setExternalUserId({
                externalUserId,
            }));
        },
        [
            externalUserId,
            userId,
        ],
    );

    function renderUnit(unit) {
        const unitIri         = _.get(unit, 'iri');
        const selectedUnitIri = _.get(selectedUnit, 'iri');

        return (
            <React.Fragment
                key={unitIri}
            >
                <WidgetUnit
                    purchasePrice={_.get(unit, 'purchasePrice', 0)}
                    propertyDescription={_.get(unit, 'property.description', '')}
                    spaceInSquareMeters={_.get(unit, 'spaceInSquareMeters', 0)}
                    roomCount={_.get(unit, 'roomCount', 0)}
                    fontColor={fontColor}
                    primaryColor={primaryColor}
                    active={_.eq(unitIri, selectedUnitIri)}
                    onClick={updateCalculationField(CalculationFields.unit, unit)}
                />
            </React.Fragment>
        );
    }

    function onRequestCalculationClicked() {
        setShowContactForm(true);
        dispatch(WidgetActions.openContactForm());
    }

    function renderContent() {
        if (showContactForm) {
            return (
                <WidgetContactForm
                    fontColor={fontColor}
                    primaryColor={primaryColor}
                    accentColor={accentColor}
                />
            );
        }

        return (
            <div className={styles.flexRow}>
                <div className={styles.widgetUnitAndSliderWrapper}>
                    <div className={styles.widgetScreenUnits}>
                        {_.map(units, renderUnit)}
                    </div>
                    <div className={styles.widgetSlidersWrapper}>
                        <div className={styles.widgetSliders}>
                            <RangeSlider
                                value={_.get(calculation, CalculationFields.interest, 0)}
                                min={ConfigurationValues.interest.minimumValue}
                                step={ConfigurationValues.interest.step}
                                max={ConfigurationValues.interest.maximumValue}
                                label={I18n.t('screens.widgetScreen.interest')}
                                type={RangeSliderType.percent}
                                fontColor={fontColor}
                                onChange={updateCalculationField(CalculationFields.interest)}
                            />
                            <RangeSlider
                                value={_.get(calculation, CalculationFields.redemption, 0)}
                                min={ConfigurationValues.redemption.minimumValue}
                                step={ConfigurationValues.redemption.step}
                                max={ConfigurationValues.redemption.maximumValue}
                                label={I18n.t('screens.widgetScreen.redemption')}
                                type={RangeSliderType.percent}
                                fontColor={fontColor}
                                onChange={updateCalculationField(CalculationFields.redemption)}
                            />
                            <RangeSlider
                                value={_.get(calculation, CalculationFields.income, 0)}
                                min={ConfigurationValues.income.minimumValue}
                                step={ConfigurationValues.income.step}
                                max={ConfigurationValues.income.maximumValue}
                                label={I18n.t('screens.widgetScreen.income')}
                                type={RangeSliderType.currency}
                                fontColor={fontColor}
                                onChange={updateCalculationField(CalculationFields.income)}
                            />
                            <RangeSlider
                                value={_.get(calculation, CalculationFields.equity, 0)}
                                min={ConfigurationValues.equity.minimumValue}
                                step={ConfigurationValues.equity.step}
                                max={ConfigurationValues.equity.maximumValue(currentPurchasePrice)}
                                label={I18n.t('screens.widgetScreen.equity')}
                                type={RangeSliderType.currency}
                                fontColor={fontColor}
                                onChange={updateCalculationField(CalculationFields.equity)}
                            />
                            <RadioCheckBox
                                label={I18n.t('screens.widgetScreen.familyStatus')}
                                fontColor={fontColor}
                                primaryColor={primaryColor}
                                options={getCivilStatusOptions()}
                                value={_.get(calculation, CalculationFields.civilStatus, CivilStatusType.single)}
                                onChange={updateCalculationField(CalculationFields.civilStatus)}
                            />
                            <RadioCheckBox
                                label={I18n.t('screens.widgetScreen.churchTax')}
                                fontColor={fontColor}
                                primaryColor={primaryColor}
                                options={[
                                    {
                                        label: I18n.t('none'),
                                        value: 0,
                                    },
                                    {
                                        label: Formatter.formatToPercent(8),
                                        value: 8,
                                    },
                                    {
                                        label: Formatter.formatToPercent(9),
                                        value: 9,
                                    },
                                ]}
                                value={_.get(calculation, CalculationFields.churchTaxPercentage, 0)}
                                onChange={updateCalculationField(CalculationFields.churchTaxPercentage)}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.widgetResultWrapper}>
                    <WidgetResult
                        resultInEuro={cashflowPerMonth}
                        accentColor={accentColor}
                        primaryColor={primaryColor}
                        fontColor={fontColor}
                        onClick={onRequestCalculationClicked}
                    />
                </div>
            </div>
        );
    }

    return (
        <div
            className={styles.widgetScreen}
            style={backgroundColorStyle}
        >
            <div className={styles.widgetScreenContainer}>
                {renderContent()}
            </div>
        </div>
    );
};

export default CalculationWidget;
