//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes   from '@components/PropTypes';
import Button      from '@stateless/atomic/Button';
import ButtonColor from '@stateless/atomic/Button/ButtonColor';
import ButtonType  from '@stateless/atomic/Button/ButtonType';
import IconType    from '@stateless/atomic/Icon/IconType';
import Spacer      from '@stateless/atomic/Spacer';

import styles from './styles.module.scss';

const propTypes = {
    addFacilityClicked:    PropTypes.func,
    addText:               PropTypes.string,
    deleteFacilityClicked: PropTypes.func,
    emptyText:             PropTypes.string,
    facilityFields:        PropTypes.object,
    facilityList:          PropTypes.array,
    gridColumnStyle:       PropTypes.string,
};

const FacilityList = ({
    facilityList,
    addFacilityClicked,
    deleteFacilityClicked,
    emptyText,
    facilityFields,
    addText,
    gridColumnStyle,
}) => {
    function onDeleteFacilityClicked(index) {
        return () => {
            deleteFacilityClicked(index);
        };
    }

    function renderEmptyText() {
        if (facilityList.length === 0) {
            return (
                <div className={styles.emptyText}>
                    {emptyText}
                </div>
            );
        }

        return null;
    }

    function renderFacilityFields(index) {
        return facilityFields.map((field) => field.renderInput(index));
    }

    function renderFacilities() {
        return _.map(facilityList, (facility, index) => {
            return (
                <div
                    className={styles.tableRowContainer}
                    style={gridColumnStyle}
                    key={index}
                >
                    {renderFacilityFields(index)}
                    <Button
                        type={ButtonType.icon}
                        color={ButtonColor.white}
                        iconLeft={IconType.trash}
                        onClick={onDeleteFacilityClicked(index)}
                    />
                </div>
            );
        });
    }

    function renderHeaderRow() {
        return _.map(facilityFields, (field) => {
            return (
                <div>
                    {field.headerText}
                </div>
            );
        });
    }

    return (
        <div className={styles.facilityList}>
            <div
                className={styles.headerRow}
                style={gridColumnStyle}
            >
                {renderHeaderRow()}
            </div>
            <Spacer height={10} />
            {renderFacilities()}
            {renderEmptyText()}
            <Spacer height={10} />
            <Button
                type={ButtonType.edgy}
                color={ButtonColor.white}
                text={addText}
                onClick={addFacilityClicked}
            />
        </div>
    );
};

FacilityList.propTypes = propTypes;

export default FacilityList;
